<template>
  <div class="lab-recover-password">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
      <div class="hero-title">
        <h3 class="title is-3 has-text-centered pt-6 txt-white">
        </h3>
      </div>
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="lab-recover-password-wrap">
          <div class="columns content-lab-recover-password-wrap">
            <div class="column is-12 reset-password-wrap">
              <h4 class="title has-text-centered pb-5">Recupera la contraseña</h4>
              <form class="overflow-h" onsubmit="return false">
                <div class="field mb-5">
                  <input-text :placeholder="'Email*'" v-model="email" :name="'email'" :is-required="false"></input-text>
                </div>
                <div class="field">
                  <p class="control">
                    <button class="button is-primary is-fullwidth is-medium mt-4" @click="recoverPassword()" :disabled="email === ''">
                      ENVIAR
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import InputText from '@/components/FormElements/InputText.vue'
export default {
  name: 'LabRecoverPassword',
  components: {
    HeroImage,
    Footer,
    InputText
  },
  data () {
    return {
      email: ''
    }
  },
  methods: {
    recoverPassword () {
      const self = this
      this.$store.dispatch('user/recoverPassword', {
        email: this.email,
        cb: (response) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Se ha enviado un correo electrónico para restablecer la contraseña.'
            }
          })
          self.$router.push({ path: '/laboratory/login' })
        },
        cbError: (error) => {
          console.log(error)
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
.lab-recover-password{
  width: 100%;
  section{
    width: 100%;
    padding: 0px 0px;
    &.over-hero{
      position: relative;
      margin-top: -340px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .lab-recover-password-wrap{
      margin: 0 auto;
      margin-bottom: 120px;
      width: 50%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-lab-recover-password-wrap{
        padding: 60px;
        .recover-password-wrap{
          padding: 0px 60px;
          form{
            .recover-pwd{
              float: right;
              text-decoration: underline;
            }
            button{
              float: left;
              margin-top: 48px;
            }
          }
          .error-recover-password-wrap{
            position: relative;
            top: 14px;
            p{
              padding: 7px;
              &.errorMessage{
                color: red;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .my-account-login{
    section{
      .lab-recover-password-wrap{
        .content-lab-recover-password-wrap{
          padding: 35px;
          .recover-password-wrap{
            border-right: 0px;
            overflow: hidden;
            form{
              button{
                display: block;
                line-height: 0px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .my-account-login{
    section{
      .lab-recover-password-wrap{
        margin-bottom: 0px;
        .content-lab-recover-password-wrap{
          .recover-password-wrap{
            padding: 0 14px;
          }
        }
      }
    }
  }
}
</style>
